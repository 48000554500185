.userview {
  align-items: end;
}
.userview .userdetails .users {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  text-align: start;
  gap: 100px;
}
.userview .userdetails .users span {
  width: 100%;
}
.userview .userdetails .users h5 {
  padding-bottom: 15px;
  width: 100%;
}
@media screen and (max-width: 1600px) {
  .userview .users {
    gap: 20px !important;
  }
}

.addCategory {
  gap: 4rem;
  align-items: center;
}
.filters {
  display: flex;
  align-items: center;
}
.filters .react-datepicker-wrapper {
  margin-inline: 2rem;
}
.filters .react-datepicker-wrapper input {
  padding: 0.4rem 1rem;
  border-radius: 0.25rem !important;
  border: 1px solid #78b833;
  flex: 1;
  min-width: 300px;
}
.filters .import-btn {
  margin-inline: 1rem;
}
.filters button {
  white-space: nowrap;
}
.fkmpGg {
  justify-content: flex-start;
  margin-block: 2px;
}

.doctor_add {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.doctor_add p {
  background-color: #6259ca;
  color: #fff;
  padding: 8px;
}

.people_list .card {
  min-height: 371px !important;
  margin-bottom: 30px;
}
.people_list .card .userdetails {
  padding: 30px;
}
.people_list .card .userdetails .users {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  text-align: start;
  gap: 30px;
}
.people_list .card .userdetails .users h6 {
  padding-bottom: 15px;
  width: 100%;
}
.people_list .card .userdetails .users p {
  width: 100%;
}

.pemission {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}
.pemission p {
  font-size: 25px;
  font-weight: 600;
}

.peple_tile {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
}

.people_btns {
  display: flex;
  justify-content: space-between;
}

.doctor_add p {
  color: #1976d2;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent !important;
  border: 1px solid #5e9ede;
  border-radius: 5px;
}

.about_doctor {
  width: 80% !important;
}

.about_text {
  text-align: start !important;
}
.about_text span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.doctor_name {
  display: flex;
  align-items: center;
  gap: 20px !important;
}
.doctor_name .doctor_img {
  width: 35%;
  -o-object-fit: contain;
  object-fit: contain;
}

@media screen and (max-width: 1200px) {
  .doctor_name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px !important;
  }
  .doctor_name .doctor_img {
    width: 60%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}
.doctor_img {
  width: 50px;
  height: 50px;
}

.user_pro {
  display: flex;
  align-items: center;
  gap: 20px;
} /*# sourceMappingURL=user.css.map */
