.userview {
  align-items: end;
  .userdetails {
    .users {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      text-align: start;
      gap: 100px;

      h5 {
        padding-bottom: 15px;
        width: 100%;
      }

      span {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .users {
      gap: 20px !important;
    }
  }
}

.doctor_add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    background-color: #6259ca;
    color: #fff;
    padding: 8px;
  }
}

.people_list {
  .card {
    min-height: 371px !important;
    margin-bottom: 30px;

    .userdetails {
      padding: 30px;

      .users {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        text-align: start;
        gap: 30px;

        h6 {
          padding-bottom: 15px;
          width: 100%;
        }

        p {
          width: 100%;
        }
      }
    }
  }
}

.pemission {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;

  p {
    font-size: 25px;
    font-weight: 600;
  }
}

// .updateuser {
//   Label {
//     // padding-top: 10px !important;
//   }
// }

.peple_tile {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
}

.people_btns {
  display: flex;
  justify-content: space-between;
}

.doctor_add {
  p {
    color: #1976d2;
    font-size: 16px;
    font-weight: 500;
    background-color: transparent !important;
    border: 1px solid #5e9ede;
    border-radius: 5px;
  }
}

.about_doctor {
  width: 80% !important;
}

.about_text {
  text-align: start !important;
  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.doctor_name {
  display: flex;
  align-items: center;
  gap: 20px !important;
  .doctor_img {
    width: 35%;
    object-fit: contain;
  }
}

@media screen and (max-width: 1200px) {
  .doctor_name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px !important;
    .doctor_img {
      width: 60%;
      object-fit: contain;
    }
  }
}

.doctor_img {
  width: 50px;
  height: 50px;
}
.user_pro {
  display: flex;
  align-items: center;
  gap: 20px;
}

.details_page {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  background: #fff !important;
  border-radius: 30px;
  .customber_top {
    height: 25vh;
    background: rgb(129, 167, 190) !important;
    background: linear-gradient(90deg, rgb(238, 245, 216) 13%, #78B833 100%, #78B833 100%) !important;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    width: 100%;
    position: relative;
    .image_user {
      position: absolute;
      bottom: 0;
      left: 80px;
      border-radius: 100%;
      transform: translateY(50px);
      background-color: #fff;
      padding: 10px;
      img {
        border-radius: 100%;
      }
    }
  }
  .customber_bottom {
    margin-top: 70px;
    padding-left: 100px;
    p {
      font-size: 17px;
      font-weight: 400;
    }
  }
}

.pending {
  color: #cfa042 !important;
  padding: 9px;
  font-size: 14px;
  width: 110px;
  text-align: center;
  color: #fff;
}

.paid {
  color: #0b7d0b !important;
  padding: 9px;
  font-size: 14px;
  width: 110px;
  text-align: center;
  color: #fff;
  font-weight: 500;
}
.cancelled,
.failed {
  color: #a01616 !important;
  padding: 9px;
  font-size: 14px;
  width: 110px;
  text-align: center;
  color: #fff;
}
.processing {
  color: rgb(55, 104, 165) !important;
  padding: 9px;
  font-size: 14px;
  width: 110px;
  text-align: center;
  color: #fff;
}
.details_customer {
  display: flex;
  gap: 100px;
  align-items: center;
}
.loyality_stamp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    border: 1px solid #4c95dd;
    cursor: pointer;
    padding: 8px;
    font-weight: 500;
    &:hover {
      background-color: #1976d2;
      color: #fff;
    }
  }
}

.stamp_list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loyality_main {
  display: flex;
  gap: 20px;
  padding-left: 50px;
  padding-right: 150px;
  margin-top: 25px;
  flex-wrap: wrap;
  .loyality_details {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    min-height: 200px;
    padding: 20px;
    width: 450px;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 45px;
  }
}
.remove {
  position: relative;
  right: 0px;
  transform: translateX(240px);
}

.content_det {
  display: flex;
  align-items: center;
  gap: 20px;
  p {
    width: 100%;
  }
  span {
    width: 100%;
    p {
      padding: 8px;
    }
  }
}

.animie_shops {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
