.MuiPaper-elevation.MuiPaper-rounded {
  background-color: #ffff !important;
  color: black !important;
  width: 600px;
  overflow: scroll;
}
.add_cat {
  font-size: 16px;
  color: rgb(15, 99, 122);
  border: 1px solid rgb(87, 208, 241);
  padding: 8px;
  cursor: pointer;
  border-radius: 3px;
}
.addCategory {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.animie_shops {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
